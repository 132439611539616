.home {
    text-align: center;
    margin: auto;
    .banner {
        margin-bottom: 25px;
       .banner-heading{
            margin: 0;
            font-size: 3.5rem;
       }
       .banner-message {
        color: rgba(102,102,102)
       }
       .poop { 
            background-color: blue;
            background-image: linear-gradient(30deg, #d5d510, #964B00);
            background-size: 100%;
            background-repeat: repeat;
            background-clip: text;
            -webkit-text-fill-color: transparent; 
        }
    }
    .toilet-banner {
        margin-top: 50px;
        h1 {
            margin: 0;
        }
        .toilet-image {
            width: 100%;
        }
    }
    .container {
        height: 450px;
        margin: 40px 0;
        .feature_image {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .feature_description {
            display: flex;
            align-items: center;
        }
    }
    .feature {
        display: flex;
        flex-wrap: wrap;
        .feature_image, .feature_description {
           flex: 1;
        }
    }

    .poop-chart_image {
        width: 300px;
        height: 200px;
    }
    .poop-color-wheel {
        background-color: #f9f9f9;
        border-radius: 1%;
    }
    .poop-chart_learn_more {
        white-space: nowrap;
    }

    @media screen and(min-width: 768px){
        .banner {
            .banner-heading {
                font-size: 5.5rem;
            }
        }
        .poop-color-wheel {
            .feature_image {
                order: 1;
            }
        }
    }
}