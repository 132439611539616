
.poop-types_header {
    text-align: center;
    .poop {
        background-color: blue;
        background-image: linear-gradient(30deg, #d5d510, #964B00);
        background-size: 100%;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent; 
    }

    .poop-sub-heading {
        text-align: left;
        color: rgb(102, 102, 102);
        font-size: .85rem;
    }
}

.poop-types {
    .poop-types_list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;

        .poop-item {
            margin: 5px;
            display: flex;
            align-items: center;
            height: 52px;
        }
    }
}
