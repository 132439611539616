
.poop-color_header {
    text-align: center;

    .poop {
        background-color: blue;
        background-image: linear-gradient(30deg, #d5d510, #964B00);
        background-size: 100%;
        background-repeat: repeat;
        background-clip: text;
        -webkit-text-fill-color: transparent; 
    }
}

.color-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: space-evenly;
    height: 180px;
    
    .list-item {
        list-style: none;
    }
}