.poop-trivia {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;

    .trivia-card {
        width: 300px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}