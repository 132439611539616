.poop_type-link {
    border: solid 1px lightgray;
    display: inline-block;
    height: 100%;
    .poop-type{
        display: flex;
        align-items: center;
        height: 100%;
    }
    &.active {
        box-shadow: 0px 0px 0px 3px rgba(0, 144, 237, 0.4);
        border-radius: 0.25rem;
    }
}