.contact-us {
    display: flex;
    height: 100%;
    justify-content: center;
    margin-top: 60px;

    .contact-us__form {
        div {
            margin-bottom: 40px;
        }
    }
}
.contact-us__title {
    text-align: center;
}

.contact-us__confirmation {
    text-align: center;
    margin-top: 100px;
}