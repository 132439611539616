
.color-item {
    border: solid 1px lightgray;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-right: 20px;
}

.active {
    .color-item {
        box-shadow: 0px 0px 0px 3px rgba(0, 144, 237, 0.4);
    }
}