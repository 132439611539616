

.footer {
    .footer-container{
        display: flex;
        align-items: center;
        .logo{
            .link {
                text-decoration: none;
                color: #57606A;
            }
        }
        .links{
            flex-grow: 1;

            .link {
                text-decoration: none;
                color: #57606A;
                font-size: 0.85rem;
                &:hover {
                    text-decoration: underline;
                    color: blue;
                }
            }
            
        }

        .list-items{
            display: flex;
            justify-content: space-between;
            max-width: 20rem;
        }
    }
}