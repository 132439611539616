.App {
  display: flex;
  flex-direction: column;
   height: 100vh;

  .application-container {
    flex-grow: 1;
    max-width: 800px;
    main {
      height: 100%;
    }
  }
}